<template>
  <div class="order-check">
    <div class="order-top">
      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_pic2.png">
    </div>
    <div class="fixed-button">
      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_service.png"
           @click="handleJumpApp('service')">
      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_report.png"
           @click="handleJumpApp('report')">
    </div>
    <div class="icon-list">
      <template v-for="item in iconList">
        <div class="inner-wrap" :key="item.code">
          <img :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_icon${item.code}.png`">
          <div>
            <span>{{ item.desc1 }}</span>
            <span>{{ item.desc2 }}</span>
          </div>
        </div>
      </template>
    </div>
    <div class="privilege">
      <div class="privilege-title">
        <p class="title">更有超多会员专属生活购物特权</p>
      </div>
      <div class="privilege-list">
        <template v-for="(item, index) in privilegeList">
          <div class="list" :index="item.code">
            <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_check.png">
            <span>{{ item.desc }}</span>
          </div>
        </template>
      </div>
    </div>
    <div class="step">
      <div class="step-title">
        <p class="dot"></p>
        <p class="title">如何领取会员专属话费券？</p>
      </div>
      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_step_3.png">
    </div>
    <div class="phone">
      <p>开通会员手机号</p>
      <p>{{ phone }}</p>
    </div>
    <div class="pay-way" v-if="!$store.state.isWx && recharged == 0">
      <p>支付方式</p>
      <van-radio-group v-model="payValue" direction="horizontal">
        <van-radio name="1">微信支付</van-radio>
        <van-radio name="2">支付宝支付</van-radio>
      </van-radio-group>
    </div>
    <div v-if="recharged == 1" class="footer">
      <div class="download-bottom" id="download" @click="handleJumpULink">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_logo.png"/>
        <p class="download-jxs">下载鲸选师领话费券</p>
      </div>
    </div>
    <div v-else class="footer">
      <p>活动专享价 ￥<span>{{ putInfo.amount }}</span></p>
      <div class="pay-button">
        <img v-if="payValue == 1" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_wx.png"/>
        <img v-else src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_zfb.png"/>
        <p @click="handleActiveWXPay">立即支付</p>
      </div>
      <div class="message-tip" v-if="recharged == '0'">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_message.png" alt="">
        <p>领不到话费券全额退款！</p>
      </div>
    </div>
    <van-dialog v-model="dialogVisible" @close="closeDialog" :showConfirmButton="false" :closeOnClickOverlay="true"
                style="background-color: transparent;">
      <div v-if="dialogType == '1'" class="wrapper">
        <p>支付是否成功</p>
        <p>请确认支付状态，领取话费券</p>
        <div>
          <van-button plain round @click="handleFail">支付失败</van-button>
          <van-button round @click="handleSuccess">支付成功</van-button>
        </div>
      </div>
      <div v-else-if="dialogType == 'service'" class="wrapper">
        <div class="wx-qrcode">
          <img src="https://cdn.cailu88.com/jingxuanshi/kf_official.png"/>
        </div>
        <div class="service">
          <p class="service-time">客服服务时间：9:00-24:00</p>
          <p class="service-info">打开微信，扫描二维码添加客服</p>
        </div>
        <van-button @click="handleSaveWX">保存二维码</van-button>
      </div>
      <div v-else class="wrapper">
        <div class="quick-info">
          <div class="info-1">
            <p>活动专享价￥</p>
            <p>{{ putInfo.amount }}</p>
          </div>
          <div class="info-2">
            <p>退出恢复原价￥</p>
            <p>59.9</p>
          </div>
          <div class="info-3">
            <p>必得100元话费券，用不了全额退款！</p>
          </div>
        </div>
        <van-button class="open" @click="handleActiveWXPay">立即开通</van-button>
        <div class="refuse">
          <p @click="handleRefuseOpen">忍痛拒绝</p>
        </div>
      </div>
    </van-dialog>
    <!--    <van-overlay :show="show">-->
    <!--      <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/dilate_invite_cover.png" alt="">-->
    <!--    </van-overlay>-->
  </div>
</template>
<script>
import Vue from 'vue'
import { RadioGroup, Radio, Dialog, Button, Overlay } from 'vant'
import baseUrl from '@/utils/baseUrl'

const api = require('@/utils/api.js').api

Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Overlay)

export default {
  name: 'orderCheck',
  data () {
    return {
      phone: '',
      // 投放信息 (ulink参数)
      putInfo: {},
      //会员特权列
      privilegeList: [],
      //图标
      iconList: [],
      // 支付需要的openID
      openId: '',
      // 支付确认弹窗
      dialogVisible: false,
      // 支付方式
      payValue: '1',
      //jxs recharged
      recharged: '',
      //点击支付重定向的地址(微信环境中)
      wxRedirectUrl: '',
      pi: '',
      attach: '',
      dialogType: '1'
    }
  },
  created () {
    this.phone = this.$route.query.phone
    this.pi = this.$route.query.pi
    this.attach = localStorage.getItem('attach')
    this.recharged = this.$route.query.recharged
    this.wxRedirectUrl = this.$route.query.wxRedirectUrl
    this.openId = this.$route.query.openId
    this.iconList = [
      {
        code: '1',
        desc1: '加油',
        desc2: '75折'
      },
      {
        code: '2',
        desc1: '话费',
        desc2: '95折'
      },
      {
        code: '3',
        desc1: '电影票',
        desc2: '7折'
      },
      {
        code: '4',
        desc1: '外卖省',
        desc2: '56元'
      }
    ]
    this.privilegeList = [
      {
        code: '1',
        desc: '会员享首单0元免单，最高可享返现50元'
      },
      {
        code: '2',
        desc: '每天1元抢iPhone，未中奖全额退款'
      },
      {
        code: '3',
        desc: '网购返利拿不停，每单最高返90%'
      },
      {
        code: '4',
        desc: '分享还可拿佣金，每年省下一部iPhone钱'
      }
    ]
  },
  mounted () {
    // 注入友盟js
    const script = document.createElement('script')
    script.src = 'https://g.alicdn.com/jssdk/u-link/index.min.js'
    script.language = 'JavaScript'
    document.body.appendChild(script)
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    if (scrollTop > 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
    if (this.openId && this.recharged == 0) {
      this.handleActiveWXPay()
    }
    this.handleGetBaseInfo()
    if (localStorage.getItem('pay') == '1') {
      this.dialogType == '1'
      this.dialogVisible = true
    }
  },
  methods: {
    /*
    唤醒wx支付
     */
    handleActiveWXPay () {
      let tradeType = ''
      if (this.$store.state.isWx) {
        if (this.openId) {
          tradeType = 1
        } else {
          window.location.href = this.wxRedirectUrl
          return
        }
      } else {
        tradeType = 4
      }
      this.$axios({
        url: api.wxPay,
        method: 'post',
        params: {
          tradeType: tradeType,
          phone: this.phone,
          openId: this.openId,
          pushTemplateId: this.pi,
          attach: this.attach,
          bizType: 4,
          platform: this.payValue
        }
      }).then(res => {
        this.dialogType == '1'
        this.dialogVisible = true
        localStorage.setItem('pay', '1')
        if (this.openId) {
          this.wxApi(res.data.data)
        } else {
          if (this.payValue == 1) {
            window.location.href = res.data.data.mwebUrl
          } else if (this.payValue == 2) {
            const div = document.createElement('div')
            /* 此处form就是后台返回接收到的数据 */
            div.innerHTML = res.data.data.orderStr
            document.body.appendChild(div)
            document.forms[0].submit()
          }
        }
      }).catch(err => {
        console.info(err)
      })
    },

    /*
    微信jsapi
    */
    wxApi (info) {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          'appId': info.appId,     //公众号ID，由商户传入
          'timeStamp': info.timeStamp,         //时间戳，自1970年以来的秒数
          'nonceStr': info.nonceStr, //随机串
          'package': info.package,
          'signType': info.signType,         //微信签名方式：
          'paySign': info.paySign //微信签名
        },
        function (res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        })
    },
    /*
    跳转jxsAPP内部事件
     */
    handleJumpApp (target) {
      if (target == 'report') {
        this.$router.push({
          name: 'putReport',
          query: {
            pi: this.pi,
            attach: this.attach,
            template: 2
          }
        })
      } else {
        this.dialogType = target
        this.dialogVisible = true
      }
    },

    /*
    保存wx二维码
    */
    handleSaveWX () {
      let picUrl = 'https://cdn.cailu88.com/jingxuanshi/kf_official.png'
      let link = document.createElement('a')
      link.download = name || 'pic'
      link.href = picUrl
      link.click()
      this.rulesVisible = false
    },

    /*
    获取投放基本信息
     */
    handleGetBaseInfo () {
      this.$axios({
        url: api.baseInfo,
        method: 'get',
        params: {
          pushTemplateId: this.pi,
          bizType: 2,
          template: 2
        }
      }).then(res => {
        this.putInfo = res.data.data
      }).catch(err => {
        console.info(err)
      })
    },

    /*
    支付成功
     */
    handleSuccess () {
      this.$axios({
        url: api.privilegeCheck,
        method: 'get',
        params: {
          phone: this.phone,
          pushTemplateId: this.pi,
          redirectUrl: `${baseUrl.pageUrl}/jingxuanvip/check?pi=${this.pi}`,
          bizType: 2,
          template: 2
        }
      }).then(res => {
        this.recharged = res.data.data.recharged
        this.dialogVisible = false
        localStorage.removeItem('pay')
      }).catch(err => {
        console.info(err)
      })
    },
    /*
    失败
     */
    handleFail () {
      this.dialogVisible = false
      localStorage.removeItem('pay')
      setTimeout(() => {
        this.dialogType = ''
        this.dialogVisible = true
      }, 600)
    },
    closeDialog() {
      this.dialogVisible = false
      localStorage.removeItem('pay')
    },
    /*
    跳转友盟ulink
     */
    handleJumpULink () {
      this.$axios.post(api.clickUpload, {
        phone: this.phone,
        sceneStatus: 5,
        templateId: this.pi,
        template: 2
      }).then(res => {
      }).catch(err => {
        console.info(err)
      })
      this.$nextTick(() => {
        ULink([{
          id: 'usr1f3p1nlg6ola2', /* 平台为每个应用分配的方案Ulink ID，必填 */
          data: {
            'c': this.putInfo.c,
            'custom': this.putInfo.custom
          },
          selector: '#download',
          auto: true,
          useOpenInBrowerTips: 'default',
          proxyOpenDownload: function (defaultAction, LinkInstance) {
            if (LinkInstance.solution.type === 'scheme') {
              // qq或者微信环境特殊处理下
              if (ULink.isWechat || ULink.isQQ) {
                // 在qq或者微信环境执行内置逻辑，具体内置逻辑为:当设置了useOpenInBrowerTips字段时，qq&&微信&&scheme时，启用蒙层提示去浏览器打开
                defaultAction()
              } else {
                window.location.href = LinkInstance.solution.downloadUrl
              }
            } else if (LinkInstance.solution.type === 'universalLink') {
              // universalLink 唤起应当由服务端提供一个带重定向到appstore的universallink地址。因此，此处不应写逻辑，友盟会在近期上线universalLink 重定向功能。
            }
          },
        }])
      })
    },
    /*
    弹窗关闭
     */
    handleRefuseOpen () {
      this.dialogVisible = false
      setTimeout(() => {
        this.dialogType = '1'
      }, 1000)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .van-radio__label {
  color: #ffffff;
}

.order-check {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 80px;
  display: inline-block;
  background: linear-gradient(54deg, #2F2F29 0%, #151512 100%, #151512 100%, #151512 100%);;

  .order-top {
    & > img {
      width: 100%;
    }
  }

  .fixed-button {
    position: fixed;
    right: 0;
    top: 57px;
    display: flex;
    flex-direction: column;

    .rules {
      position: absolute;
      top: -48px;
      right: 0;
      width: 54px;
    }

    img {
      width: 34px;
      margin-bottom: 3px;
    }
  }


  .icon-list {
    display: flex;
    justify-content: space-between;
    padding: 18px 32px;

    .inner-wrap {
      text-align: center;

      img {
        width: 42px;
      }

      & > div {
        span:first-child {
          font-size: 14px;
          font-weight: 400;
          color: #E6D1AF;
          line-height: 20px;
        }

        span:last-child {
          margin-left: 2px;
          font-size: 14px;
          font-weight: 400;
          color: #FE4949;
          line-height: 20px;
        }
      }
    }
  }

  .privilege {
    .privilege-title {
      margin: 0 auto;
      padding-top: 15px;
      width: 300px;
      border-top: 1px solid #80806C43;
      text-align: center;

      .title {
        font-size: 18px;
        font-weight: bold;
        color: #E6D1AF;
        line-height: 25px;
      }
    }

    .privilege-list {
      margin-top: 10px;
      margin-left: 38px;
      display: flex;
      flex-direction: column;

      .list {
        margin-top: 4px;

        img {
          width: 14px;
        }

        span {
          margin-left: 6px;
          font-size: 15px;
          font-weight: 400;
          color: #FFFFFFFF;
          line-height: 30px;
          opacity: 0.6;
        }
      }
    }
  }

  .step {
    margin: 12px auto 0;
    padding: 12px;
    width: 326px;
    height: 106px;
    background: #FFFFFF;
    border-radius: 10px;
    text-align: center;

    .step-title {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .dot {
        width: 6px;
        height: 15px;
        background: linear-gradient(180deg, #E6C374 0%, #C99040 100%);
        border-radius: 4px;
      }

      .title {
        margin-left: 12px;
        font-size: 18px;
        font-weight: bold;
        color: #C99040;
        line-height: 25px;
      }
    }

    img {
      width: 295px;
    }
  }

  .phone {
    margin: 12px auto 0;
    padding: 12px;
    width: 326px;
    height: 26px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;

    p:first-child {
      font-size: 15px;
      font-weight: 400;
      color: #C99040;
      line-height: 21px;
    }

    p:last-child {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 25px;
    }
  }

  .pay-way {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px auto 0;
    padding: 12px;
    width: 326px;
    color: #ffffff;
  }

  .footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    height: 60px;
    background: linear-gradient(90deg, #626262 0%, #333333 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:first-child {
      margin-left: 18px;
      font-size: 15px;
      font-weight: 300;
      color: #FFFFFF;
      line-height: 21px;
      display: flex;
      align-items: center;

      span {
        margin-left: 6px;
        font-size: 30px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 42px;
      }
    }

    .pay-button {
      display: flex;
      align-items: center;
      width: 165px;
      height: 60px;
      background: linear-gradient(180deg, #E8DBB1 0%, #CDB479 100%);

      img {
        margin-left: 28px;
        width: 28px;
      }

      p {
        margin-left: 8px;
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
      }
    }

    .message-tip {
      position: absolute;
      top: -40px;
      left: 12px;

      img {
        width: 182px;
        height: 40px;
      }

      & > p {
        text-align: center;
        position: relative;
        top: -38px;
        font-size: 15px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 21px;
      }
    }

    .download-bottom {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 12px;
        width: 29px;
        height: 23px;
      }

      .download-jxs {
        font-size: 20px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #F5DAB6;
        line-height: 28px;
      }
    }
  }

  .wrapper {
    position: relative;
    display: inline-block;
    padding-bottom: 15px;
    width: 320px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;

    & > p:first-child {
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
    }

    & > p {
      font-size: 15px;
      color: #7F694E;
      line-height: 21px;
    }

    & > div {
      margin-top: 20px;

      .van-button {
        width: 136px;
        height: 50px;
        border-radius: 25px;
        font-size: 18px;
        color: #7F694E;
        line-height: 25px;
      }

      .van-button:first-child {
        margin-right: 16px;
        border: 1px solid #7F694E;
      }

      .van-button:last-child {
        background: linear-gradient(146deg, #FFE687 0%, #FFC667 100%);
        font-weight: bold;
      }
    }

    .wx-qrcode {
      display: inline-block;
      margin-top: 35px;

      & > img {
        width: 198px;
        height: 198px;
      }
    }

    .service {
      margin-top: 8px;
      margin-bottom: 14px;

      .service-info {
        font-size: 18px;
        font-weight: bold;
        color: #7F694E;
        line-height: 25px;
        margin-top: 8px;
      }

      .service-time {
        font-size: 15px;
        font-weight: 400;
        color: #7F694E;
        line-height: 21px;
      }
    }

    .van-button {
      margin-top: 10px;
      margin-bottom: 15px;
      width: 280px;
      height: 50px;
      background: linear-gradient(90deg, #FFE687 0%, #FFC667 100%);
      border-radius: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #7F694E;
      line-height: 25px;
    }

    .quick-info {
      display: inline-block;
      margin-top: 20px;

      .info-1,
      .info-2 {
        display: flex;
        align-items: center;
        justify-content: center;

        p:first-child {
          font-size: 20px;
          font-weight: bold;
          color: #7F694E;
          line-height: 28px;
        }

        p:last-child {
          margin-left: 4px;
          font-size: 26px;
          font-weight: bold;
          color: #E44A59;
          line-height: 37px;
        }
      }

      .info-3 {
        margin-top: 12px;
        font-size: 15px;
        font-weight: 400;
        color: #7F694E;
        line-height: 21px;
      }
    }

    .open {
      margin-top: 10px;
      margin-bottom: 15px;
      width: 280px;
      height: 50px;
      background: linear-gradient(90deg, #FFE687 0%, #FFC667 100%);
      border-radius: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #7F694E;
      line-height: 25px;
    }

    .refuse {
      margin-top: 4px;

      p {
        font-size: 15px;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
      }
    }
  }
}
</style>
